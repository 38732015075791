import Vue from 'vue'
import App from './App.vue'
import VueThreeSixty from 'vue-three-sixty';
import store from "@/store";
import router from "@/router";
import VueGtag from 'vue-gtag'
Vue.config.productionTip = false
Vue.use(VueThreeSixty);

function setupVueGtag(id) {
  Vue.use(VueGtag, {
    config: { id }
  }, router);
}

const url = window.location.href;
const parts = url.split('/');
const lastPart = parts[parts.length - 1];

let gtagId;
switch (lastPart) {
  case 'eliana':
    gtagId = 'G-L6X4PD8BYW';
    break;
  case 'malia':
    gtagId = 'G-2T44L0LBKM';
    break;
  case 'juno':
    gtagId = 'G-FH46W1CS25';
    break;
  default:
    gtagId = 'G-DLNHLJ2VCR';
}

setupVueGtag(gtagId);

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
