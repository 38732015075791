<template>
    <div class="qoo-main">
      
      <div class="container-fluid p-0">
        <router-view></router-view>
      </div>
        
      <OverlayLoader v-show="isLoading"/>
    </div>
  </template>
  
  <script>
  
  import OverlayLoader from "@/components/base/overlay/OverlayLoader.vue";
  
  export default {
    name: 'App',
    components: { OverlayLoader },
  
    computed: {
      isLoading: {
        get(){
          return this.$store.state.isLoading;
        }
      },
    },
    
  }
  
  </script>