//eslint-disable
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//import store from "@/store";

function lazyLoad(view) {
      return () => import(`@/views/${view}.vue`)
}

const routes = [
      {
            path: "*",
            name: "Error404",
            meta: { layout: "default", title: "Page introuvable" },
            component: lazyLoad('errors/404'),
      },
      {
            path: '/',
            redirect: '/lily',
      },
      {
            path: '/lily',
            name: 'lily',
            component: lazyLoad('Lily'),
            meta: { layout: "default", title: "Lily by Henley", requiresAuth: true },
      },
      {
            path: '/eliana',
            name: 'Eliana',
            component: lazyLoad('Eliana'),
            meta: { layout: "default", title: "Eliana by Henley", requiresAuth: true },
      },
      {
            path: '/malia',
            name: 'Malia',
            component: lazyLoad('Malia'),
            meta: { layout: "default", title: "Malia by Henley", requiresAuth: true },
      },
      {
            path: '/juno',
            name: 'Juno',
            component: lazyLoad('Juno'),
            meta: { layout: "default", title: "Juno by Henley", requiresAuth: true },
      },
];

const router = new VueRouter({
      mode: 'history',
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
      routes
});


router.beforeEach((to, from, next) => {
      if (to.meta.title) {
            document.title = to.meta.title;
      } else {
            document.title = 'Default Title'; // fallback title
      }
      next();
});

export default router;