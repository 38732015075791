import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
      state: {
            isLoading: '',
            accessToken: '',
      },
      mutations: {
            mutationer(state, payload) {
                  let keys = Object.keys(payload);
                  for (let item of keys) {
                        state[item] = payload[item]
                  }
            },
      }
});
